<template>
  <div
      class="container inside-wrapper inside-wrapper-margin"
      id="case-study"
      data-aos="fade-up"
      data-aos-duration="1000"
  >

        <div class="row back-image springboard back-white" style="">
            <div class="col-md-5 col-heading">
                <h2 class="heading wow fadeInDown text-blue">Springboard<a href="https://itunes.apple.com/us/app/springboard-collaborative/id1340715343?mt=8"><span class="lnr lnr-link"></span></a></h2>
                <br>
                <p class="text-dark wow fadeInUp text-dark subheading text-justify">

                    Springboard is an app that helps families build home reading habits by setting goals, making plans, and choosing rewards.
                    Weekly, you'll set goals for how often and how long to read at home.
                    <br><br>
                    Springboard help you plan the ‘who, what, when, where, and how’ of reading together. The app will also track your progress so that you can celebrate reaching your goals!
                </p>
                <br><br>
                <a href="https://itunes.apple.com/us/app/springboard-collaborative/id1340715343?mt=8" class="web-blue" target="_blank"><span class="web-icon-blue"><i class="fa fa-mobile "></i> </span> <span class="web-text-blue text-bold">View App</span></a>
                <br><br><br>
            </div>
        </div>
        <section class="case-study">

            <div class="row top-height">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class=" margin-top">

                                <h2 class="heding-title">1<span class="sub-title-pad sub-title-text">Challenge</span></h2>
                            </div>

                            <p class="text-dark wow fadeInUp text-justify">
                                The team behind Springboard Collaborative approached Ocean Ring Technologies about creating a simple mobile application that would foster reading habits in children with the support of their families.
                                <br><br>
                                Getting young people to read more is challenging if they don't enjoy it. But reading is fundamental to character, intellectual and emotional development as well as increasing one's creativeness. In order
                                to assist youth to read more and productively, ORT was called upon to architect and develop a mobile app that allowed families to schedule time to read with their young ones in hopes of achieving a set amount of
                                time. The mobile app had to also be available on both Android and iOS stores.
                                <br><br>
                            </p>

                        </div>
                        <div class="col-sm-4 col-sm-offset-1">
                            <div class="app-screens-mobile">
                                <img width="100%" src="../../assets/img/casestudy-springboard/01.png">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4 col-sm-offset-1">
                            <div class="app-screens-mobile hidden-xs">
                                <img width="100%" src="../../assets/img/casestudy-springboard/02.png">
                            </div>
                        </div>
                        <div class="col-sm-6 col-sm-offset-1">
                            <div class=" margin-top-section">
                                <h2 class="heding-title">2<span class="sub-title-pad sub-title-text">Obstacles</span></h2>
                            </div>
                            <ul type="none" class="contactPoints">
                                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Allow for goal setting towards the amount of time read each week</span></li>
                                <li><i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>Seeing the progress of each user and no place to analyze the data</span></li>
                                <li><i class="fa fa-minus text-blue">&nbsp;&nbsp;</i><span>Allow for quick onboarding of a user and ensuring a notification system for both mobile and email</span></li>
                                <li>
                                    <i class="fa fa-minus text-red">&nbsp;&nbsp;</i><span>
                                        Tracking feedback from users was important for future enhancements
                                    </span>
                                </li>

                            </ul>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="  margin-top">
                                <h2 class="heding-title">3<span class="sub-title-pad sub-title-text">Solution</span></h2>

                            </div>
                            <p class="text-dark wow fadeInUp text-justify">
                                The Springboard Collaborative app is a simple goal setting tracker based on the amount of time a user and their children reads. By providing the necessary tracking and a dashboard that allows the client to
                                analyze the captured data, the team is able to provide assistance and suggestions to parents about when to best read with their child. By using React Native, ORT was able to quickly architect and develop a
                                mobile application that allowed for a seamless onboarding workflow which allows users to immediately get familiar with setting a reading goal as well as deciding how to best reward children for spending more
                                time reading.
                                <br><br>
                                Utilizing Google's cloud platform we were able to quickly and efficiently create a database and API methods to handle the capturing of the data from the mobile app while also maintaining the need to be flexible
                                with some content on the app in regard to adding more rewards or providing more choices for promoting of increased reading time. Based on initial feedback of the app using SurveyMonkey we are able to track
                                user response using a dynamic survey the team can update quickly and unintrusively.
                                <br><br>
                            </p>
                        </div>
                        <div class="col-sm-4 col-sm-offset-1">
                            <div class="app-screens-mobile">
                                <img width="100%" src="../../assets/img/casestudy-springboard/03.png">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-8  col-technologies text">
                        <h2 class="text-dark border-dotted-blue">Whichever services you require, we have the skills to match.</h2>
                        <router-link to="/contact" class="btn-red hvr-sweep-to-right hvr-sweep-to-right-red">Contact</router-link>
                    </div>
        </div></div></section>

<div class="row contact-fourth back-red">
      <div class="col-sm-5 bottom-links text-left">
        <router-link to="/">
          <h2 class="heading text-light">
            <i class="fa fa-long-arrow-left"> </i> Home
          </h2>
        </router-link>
      </div>
      <div class="col-sm-2">
        <p></p>
      </div>
      <div class="col-sm-5 bottom-links text-right">
        <router-link to="/news">
          <h2 class="heading text-light">
            News <i class="fa fa-long-arrow-right"> </i>
          </h2>
        </router-link>
      </div>
    </div>

            </div>
</template>

<script>
export default {
  name: "work",
  async mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
